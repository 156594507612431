import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { GitHub, Mail, Linkedin } from 'react-feather';
import { Helmet } from 'react-helmet';
import ReactTypingEffect from 'react-typing-effect';
import tw from 'twin.macro';

const roles = [
  'Full Stack Engineer',
  'Back-end Engineer',
  'Front-end Engineer',
  'Lead Software Engineer',
  'React Developer',
  'C# Guru',
  '.NET Core Developer',
  'Technology Enthusiast',
  'DevOps',
  'Pragmatist',
  'TypeScript Developer',
  'Angular Developer',
  'SQL Developer',
  'REST API Developer',
  'GatsbyJS Developer',
  'ASP.NET Core Wizard',
  'Application Architect',
  'Microservices Architect',
];

const SocialLink = tw.a`text-gray-400 hover:text-gray-200`;

export default function Home() {
  const [rolesRandomized, setRolesRandomized] = useState<string[]>();

  useEffect(() => {
    setRolesRandomized([...roles].sort(() => Math.random() - 0.5));
  }, []);

  return (
    <div tw="h-screen bg-gray-900 flex flex-col md:flex-row">
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Will Graham</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Will Graham. Charlotte, NC, area lead software engineer."
        />
      </Helmet>
      <div tw="w-full h-1/2 md:(w-1/2 h-full) overflow-hidden">
        <StaticImage
          src="../images/image.jpg"
          alt="Keyboard"
          placeholder="blurred"
          style={{ height: '100%' }}
          width={1200}
        />
      </div>
      <div tw="w-full md:w-1/2 p-8 text-gray-200 my-auto mx-auto">
        <div tw="flex flex-row space-x-4 items-end">
          <h1 tw="text-2xl md:text-3xl lg:text-4xl">Will Graham</h1>
          <SocialLink href="mailto:will@wgraham.me" aria-label="Send me an email">
            <Mail size={16} />
          </SocialLink>
          <SocialLink href="https://github.com/wgraham17/" aria-label="View my profile on GitHub">
            <GitHub size={16} />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/wgraham17/"
            aria-label="View my profile on LinkedIn"
          >
            <Linkedin size={16} />
          </SocialLink>
        </div>
        <h2 tw="text-xl md:text-2xl lg:text-3xl mt-2 text-gray-400">
          <ReactTypingEffect
            text={rolesRandomized}
            speed={50}
            eraseSpeed={25}
            eraseDelay={1000}
            typingDelay={500}
          />
        </h2>
      </div>
    </div>
  );
}
